import { AutomatitCarousel } from "./automatit_carousel.m.js";
import { getMapsHelper } from "./util/mapsHelper.js";

if(document.getElementById('map_canvas')){
	const mapsHelper = getMapsHelper();//get an instance of the mapsHelper
	mapsHelper.ready().then(()=>{
		// Facility Info map
		const theMap = mapsHelper.createMap({
			element : document.querySelector("#map_canvas"),
			locationElementSelector : "#map_canvas",
			useRichmarker: true,
			markerReducer : (el) => {
				return {
					lat : el.getAttribute("data-lat"),
					lng : el.getAttribute("data-lng"),
					content: `<span class="map_pin"><img src="/static/images/map_pin.png"></span>`
				}
			}
		});
		theMap.addListener('idle',()=>theMap.setZoom(15))
	});
}

if(document.getElementById('hero')){
	AutomatitCarousel({
		element: document.getElementById('hero'),
		speed:1000,
		intervalTiming:5000,
		useChevrons: false,
		showDots: true
	})
}
if(document.getElementById('features_gallery')){
	AutomatitCarousel({
		element: document.getElementById('features_gallery'),
		speed:1000,
		intervalTiming:5000,
		useChevrons: false,
		showDots: true
	})
}
if(document.getElementById('about_gallery')){
	AutomatitCarousel({
		element: document.getElementById('about_gallery'),
		speed:1000,
		intervalTiming:5000,
		useChevrons: false,
		showDots: true
	})
}
$(function(){

	//> Mobile Menu
	$('.mobile_menu').click(function(){
		var time = 100;
		$(this).toggleClass('close');
		if($('#mobile_nav .menu').hasClass('open')) {
			setTimeout(function(){
				$('#mobile_nav .menu').removeClass('open');
			},50);
			var items = $('#mobile_nav .menu a');
			items = $(items.get().reverse());
			items.each(function(){
				var current = $(this);
				setTimeout(function(){
					current.toggleClass('active');
				}, time);
				time += 60
			});
		} else {
			$('#mobile_nav .menu').addClass('open');
			$('#mobile_nav .menu a').each(function(){
				var current = $(this);
				setTimeout(function(){
					current.toggleClass('active');
				}, time);
				time += 90
			});
		}
	});
	
	//> Carousel Initation 
	// if($('.carousel').length > 0) {
		// var hero = {
		// };
		// $('#hero').automatitCarousel(hero);
		
		
		// var features = {
		// 	speed:1000,
		// 	intervalTiming:5000,
		// 	useChevrons: false,
		// 	showDots: true
		// };
		// $('#features_gallery').automatitCarousel(features);

		// var about = {
		// 	speed:1000,
		// 	intervalTiming:5000,
		// 	useChevrons: false,
		// 	showDots: true
		// };
		// $('#about_gallery').automatitCarousel(about);
	// }

	// > Contact Page
	if($('#contact.internal').length > 0){
		var subIP = false;
		$('#contact_form_submit').on('click touchend', function(e){
			if($('#hp').val() != '')return false;

			$('#contact_form .error').removeClass('error');
			$('.response_message').html('');

			var checkFields = new Array('name','email','message');
			var errorFound = false;
			checkFields.forEach(function(item){
				if($('#'+item+'_input').val() == ''){
					$('#'+item+'_input').addClass('error');
					errorFound = true;
				}
			});
			var formdata = $('#contact_form').serialize();
			var formAction = $('#contact_form').attr('action');
			if(! subIP && ! errorFound){
				subIP = true;
				$.ajax({
					data: formdata,
					method: 'POST',
					url: formAction,
					success: function(returnData,status){
						$('.response_message').html(returnData);
						if($('.response_message .error').length == 0){
							$('#contact_form_submit, .input').detach();
						}
					},
					error: function(){
						$('.response_message').html("<span class='error'>There was a problem processing your request, please try again!</span>");
					},
					complete: function(){
						subIP = false;
					}
				});
			}
			else {
				$('.response_message').html("<span class='error'>Please fill out all required fields.</span>");
			}
			e.stopImmediatePropagation();
		});
	}


	// if($('#map_canvas').length > 0) {
    //     initMap();
	// }

});

//>initMap
// function initMap(){
//     var lat = document.getElementById('map_canvas').getAttribute('data-lat');
//     var lng = document.getElementById('map_canvas').getAttribute('data-lng');
//     var pin = new google.maps.LatLng(lat, lng);
//     var myOptions = {
//         mapTypeId: google.maps.MapTypeId.ROADMAP,
//         scrollwheel: false
//     }
//     var map = new google.maps.Map(document.getElementById("map_canvas"), myOptions);
//     var marker = new google.maps.Marker({
//         position: pin,
//         icon: '/assets/images/map_pin.png',
//         animation: google.maps.Animation.DROP,
//         map: map
//     });
//     map.setCenter(pin);
//     map.setZoom(15);
// }